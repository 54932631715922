/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable quotes */
/* eslint-disable react/jsx-props-no-spreading */
import { useLocation, useParams } from "react-router-dom";

export default function withRouter(Component) {
	// eslint-disable-next-line indent
	function ComponentWithRouterProp(props) {
		const location = useLocation();
		const params = useParams();
		return <Component {...props} router={{ location, params }} />;
	}
	return ComponentWithRouterProp;
}
