/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Divider, Card,
} from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { LockOutlined, LockOpenOutlined } from '@mui/icons-material';
import { useSettingsContext } from '../contexts/SettingsContext';
import { menuItems } from '../data/menu';
import BreakGap from '../components/BreakGap';
import MiniRadio from '../components/radio/MiniRadio';
import Arjit from './ArjitIcon';
import { useAuthContext } from '../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  menuitem: {
    textDecoration: 'none',
    color: '#f9fafb',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    padding: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(2),
    borderRadius: 6,
    width: '100%',
  },
  activemenu: {
    backgroundColor: 'var(--hover-menu-bg)',
    borderRadius: 6,
  },
  cardlink: {
  },
}));

function CustomMenuItem({ children, path }) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Card
      className={classes.cardlink}
      elevation={2}
      sx={{
        marginTop: 2,
        display: 'flex',
        borderRadius: 6,
        paddingLeft: '12px',
        border: '1px solid #fff1',
        '&:hover': {
          background: '#0c0f194d',
        },
        background: location.pathname === path ? '#0c0f194d' : 'transparent',
      }}
    >
      {children}
    </Card>
  );
}

function SideMenu() {
  const { dock } = useSettingsContext();
  const classes = useStyles();
  const { isLoggedin } = useAuthContext();

  const {
    stations,
  } = useSelector(({ radio }) => radio);

  if (dock) return null;

  const LockIcon = isLoggedin ? LockOpenOutlined : LockOutlined;
  const showMiniRadio = false;
  return (
    <div className="left-side" style={{ position: 'relative' }}>
      {
        stations.length > 0 && showMiniRadio && (
          <>
            <div
              className="side-wrapper"
              style={{ marginBottom: 20 }}
            >
              <MiniRadio />
            </div>
            <Divider style={{ marginBottom: 20 }} />
          </>
        )
      }
      <div className="side-wrapper">
        <div className="side-title">Resume</div>
        <div>
          {
            menuItems.data.filter((d) => d.category === 'resume').map(({ name, path, FaIcon }) => (
              <CustomMenuItem path={path} key={path}>
                <Link className={clsx(classes.menuitem)} to={path}>
                  <FaIcon style={{ marginRight: 10 }} area-disabled="true" />
                  <span>{name}</span>
                </Link>
              </CustomMenuItem>
            ))
          }
        </div>
      </div>
      <BreakGap />
      {/* <Divider style={{ marginBottom: 10 }} />
      <div className="side-wrapper">
        <div>
          {
            menuItems.data.filter((d) => d.category === 'secure').map(({
              name, path, FaIcon, secure,
            }) => (
              <CustomMenuItem path={path} key={path}>
                <Link className={clsx(classes.menuitem)} to={path}>
                  {
                    path === '/secure/arjitworld'
                      ? (
                        <>
                          <Arjit fill="#fff" />
                          &nbsp;&nbsp;
                        </>
                      )
                      : <FaIcon style={{ marginRight: 10 }} />
                  }
                  <span>{name}</span>
                  {
                    secure && <LockIcon style={{ marginLeft: 'auto' }} />
                  }
                </Link>
              </CustomMenuItem>
            ))
          }
        </div>
      </div> */}
      <BreakGap />
      <Divider style={{ marginBottom: 10 }} />
      <div className="side-wrapper">
        <div className="side-title">Deployed Apps</div>
        <div>
          {
            menuItems.data.filter((d) => d.category === 'apps').map(({
              name, path, FaIcon, secure,
            }) => (
              <CustomMenuItem path={path} key={path}>
                <Link className={clsx(classes.menuitem)} to={path}>
                  {
                    path === '/arjitworld'
                      ? (
                        <>
                          <Arjit fill="#fff" />
                          &nbsp;&nbsp;
                        </>
                      )
                      : <FaIcon style={{ marginRight: 10 }} />
                  }
                  <span>{name}</span>
                  {
                    secure && <LockOutlined style={{ marginLeft: 'auto' }} />
                  }
                </Link>
              </CustomMenuItem>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
