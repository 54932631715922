/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import { RadioBrowserApi } from 'radio-browser-api';
import axios from 'axios';
import radioConstants from '../constants/radioConstants';
import { getFavInitials } from '../../utils/jsHelpers';

export const getStations = (variables, station = null) => async (dispatch) => {
    const api = new RadioBrowserApi('Raja Polavarapu Radio App');
    dispatch({
        type: radioConstants.LOADING,
        payload: true,
    });
    const { countryCode } = variables;

    fetch(`https://nl1.api.radio-browser.info/json/stations/search?countrycode=${countryCode}&limit=1000&order=votes&reverse=true&removeduplicates=true&hidebroken=true`)
        .then((d) => d.json())
        .then((data) => {
            const updatedStations = data?.filter((d) => d?.url_resolved?.match(/https:\/\//g))
                .map((item, index) => ({
                    ...item,
                    urlResolved: item?.url_resolved,
                    index,
                }));

            if (!station) {
                dispatch({
                    type: radioConstants.SET_STATION,
                    payload: updatedStations[0],
                    loading: false,
                });
            }
            dispatch({
                type: radioConstants.GET_STATIONS_SUCCESS,
                payload: updatedStations,
                loading: false,
            });
        })
        .catch(() => {
            dispatch({
                type: radioConstants.GET_STATIONS_FAILED,
                payload: [],
                loading: false,
            });
        });
};

export const getCountries = () => async (dispatch) => {
    fetch('https://de1.api.radio-browser.info/json/countrycodes/?hidebroken=true&limit=100&reverse=true&order=stationcount')
        .then((d) => d.json())
        .then((d) => {
            dispatch({
                type: radioConstants.GET_COUNTRIES_SUCCESS,
                payload: d,
                loading: false,
            });
        })
        .catch((error) => {
            dispatch({
                type: radioConstants.GET_COUNTRIES_SUCCESS,
                payload: [{ name: 'IN', stationcount: 222 }],
                loading: false,
            });
        });
};

export const getLanguages = () => async (dispatch) => {
    fetch('https://de1.api.radio-browser.info/json/languages?hidebroken=true&limit=100&reverse=true&order=stationcount')
        .then((d) => d.json())
        .then((data) => {
            dispatch({
                type: radioConstants.GET_LANG_SUCCESS,
                payload: data,
                loading: false,
            });
        })
        .catch((error) => {
            dispatch({
                type: radioConstants.GET_LANG_SUCCESS,
                payload: [{ name: 'telugu', iso_639: 'te', stationcount: 10 }],
                loading: false,
            });
        });
};

export const setStation = (station) => async (dispatch) => {
    dispatch({
        type: radioConstants.SET_STATION,
        payload: station,
    });
};

export const setSetting = (setting) => async (dispatch) => {
    dispatch({
        type: radioConstants.SET_PLAYER_PROPERTIES,
        payload: setting,
    });
};

export const playPrevStation = () => async (dispatch) => {
    dispatch({
        type: radioConstants.PLAY_PREV_STATION,
        payload: 'prev',
    });
};

export const playNextStation = () => async (dispatch) => {
    dispatch({
        type: radioConstants.PLAY_NEXT_STATION,
        payload: 'next',
    });
};

export const favIt = (variables) => async (dispatch) => {
    dispatch({
        type: radioConstants.FAV_UNFAV_IT,
        payload: variables,
    });
};

export const setFavsFromStorage = () => async (dispatch) => {
    dispatch({
        type: radioConstants.SET_FAVS,
        payload: getFavInitials(),
    });
};

export const openFavMenuAction = (openState) => async (dispatch) => {
    dispatch({
        type: radioConstants.OPEN_FAV_MENU,
        payload: openState,
    });
};
