/* eslint-disable indent */
import gitaConstants from '../constants/gitaConstants';

const initialState = {
    chapters: [],
    verses: {},
    chapter: {},
    onlyverses: {},
    loading: true,
};

const gitaReducer = (state = initialState, action = {}) => {
    const { payload, type } = action;
    switch (type) {
        case gitaConstants.LOADING_CHAPTERS:
        case gitaConstants.LOADING_CHAPTER:
            return {
                ...state,
                loading: payload,
            };

        case gitaConstants.GET_CHAPTERS_SUCCESS:
            return {
                ...state,
                chapters: payload,
                loading: action.loading,
            };

        case gitaConstants.GET_CHAPTER_SUCCESS:
        case gitaConstants.GET_CHAPTER_FAILURE:
            return {
                ...state,
                chapter: {
                    ...state.chapter,
                    ...payload,
                },
                loading: action.loading,
            };

        case gitaConstants.GET_VERSE_SUCCESS:
        case gitaConstants.GET_VERSE_FAILURE:
            return {
                ...state,
                verses: {
                    ...state.verses,
                    ...payload,
                },
                loading: action.loading,
            };

        case gitaConstants.GET_ONLY_VERSE_SUCCESS:
        case gitaConstants.GET_ONLY_VERSE_FAILURE:
            return {
                ...state,
                onlyverses: {
                    ...state.verses,
                    ...payload,
                },
                loading: action.loading,
            };

        default:
            return state;
    }
};

export default gitaReducer;
