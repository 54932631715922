/* eslint-disable indent */
export default {
    GET_CHAPTERS_SUCCESS: 'GET_CHAPTERS_SUCCESS',
    GET_CHAPTERS_FAILURE: 'GET_CHAPTERS_FAILURE',
    GET_VERSE_SUCCESS: 'GET_VERSE_SUCCESS',
    GET_VERSE_FAILURE: 'GET_VERSE_FAILURE',
    GET_CHAPTER_SUCCESS: 'GET_CHAPTER_SUCCESS',
    GET_CHAPTER_FAILURE: 'GET_CHAPTER_FAILURE',
    LOADING_CHAPTER: 'LOADING_CHAPTER',
    LOADING_CHAPTERS: 'LOADING_CHAPTERS',
    LOADING_VERSE: 'LOADING_VERSE',
    GET_ONLY_VERSE_SUCCESS: 'GET_ONLY_VERSE_SUCCESS',
    GET_ONLY_VERSE_FAILURE: 'GET_ONLY_VERSE_FAILURE',

};
