/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
import AppBar from '@mui/material/AppBar';
import {
  Box, Tabs, Tab, styled, CssBaseline, Grid, Divider, Card, Hidden,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import {
  // Outlet, useLocation, useParams, useRoutes, useSearchParams,
  // useResolvedPath, useHistory, resolvePath,
  BrowserRouter, HashRouter, Link, MemoryRouter, NavLink,
  Navigate, Outlet, Route, Router, Routes, UNSAFE_LocationContext, UNSAFE_NavigationContext,
  UNSAFE_RouteContext, createRoutesFromChildren, createSearchParams, generatePath, matchPath,
  matchRoutes, renderMatches, resolvePath, unstable_HistoryRouter, useHref, useInRouterContext,
  useLinkClickHandler, useLocation, useMatch, useNavigate, useNavigationType, useOutlet,
  useOutletContext, useParams, useResolvedPath, useRoutes, useSearchParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import arjit from '../assets/images/gitabanner.png';
import TabPanel from '../common/TabPanel';
import Loading from '../components/Loading';
import withRouter from '../HOCs/WithRouter';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${arjit}) #fff6 no-repeat`,
    width: '100%',
    height: '220px',
    backgroundPosition: 'top left',
    backgroundSize: '100%',
    paddingTop: 10,
    [theme.breakpoints.down('lg')]: {
      height: '100px',
      backgroundColor: '#fff3 !important',
      backgroundSize: '100% 100px',
    },
    backgroundColor: '#fff6 !important',
    transition: '0.3s ease',
    textAlign: 'center',
    padding: 15,
    position: 'relative',
  },
  tab: {
    textDecoration: 'none',
    color: '#f9fafb',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(2),
    borderRadius: 6,
    transition: '0.3s ease-in',
  },
  glass: {
    backgroundColor: '#9297b321 !important',
    color: '#fff',
    transition: '0.3s ease',
    padding: 10,
    border: '1px solid #fff1',
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    height: 10,
    backgroundColor: '#fff',
  },

});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
    color: 'rgba(255, 255, 255, 0.5)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

function GitaLayout({ router: { location, params } }) {
  const [value, setValue] = React.useState(location.pathname);
  const ref = React.useRef();
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [width, setWidth] = React.useState(0);
  React.useEffect(async () => {
    setWidth(ref.current.clientWidth);
  }, []);

  const [chapters, setChapters] = React.useState({ chapter: 1, verse: 1 });
  const [autoplay, setAutoPlay] = React.useState(false);

  const [verseno, setVerseNo] = React.useState(parseInt(params.verse, 10) || 1);
  const [cverse, setCVerse] = React.useState(parseInt(params.cverse, 10) || 1);
  const [chapterno, setChapterNo] = React.useState(parseInt(params.chapter, 10) || 1);

  const { chapter, verse } = chapters;

  const [tablist, setTablist] = React.useState([
    { name: 'Home', to: '/gita/home' },
    { name: 'Chapters', to: `/gita/chapter/${chapter}/${cverse}` },
    { name: 'Verses', to: `/gita/verse/${verse}` },
  ]);

  const setTabs = (key, path) => {
    setTablist(tablist.map((d) => {
      if (d.name === key) {
        return {
          ...d,
          to: path,
        };
      }
      return d;
    }));
  };

  const [title] = React.useState('Raja Polavarapu - Developer Portfolio');

  React.useEffect(() => {
    if (location.pathname !== '/gita/home') {
      setChapters({
        ...chapters,
        ...params,
      });
      const key = Object.keys(params)[0];
      if (params.chapter && params.cverse) {
        const ver = parseInt(params.cverse, 10) || 1;
        const chap = parseInt(params.chapter, 10) || 1;
        const path = `/gita/${key}/${chap || 1}/${ver || 1}`;
        setValue(path);
        setTabs('Chapters', path);
      } else {
        const path = `/gita/${key}/${params[key] || 1}`;
        setValue(path);
        setTabs('Verses', path);
      }
    } else {
      setValue('/gita/home');
    }
  }, [params]);

  return (
    <div style={{ overflow: 'auto', height: '100%' }} ref={ref}>
      <CssBaseline />
      <AppBar
        position="relative"
        elevation={0}
        sx={{
          bgcolor: '#fff3',
          borderBottom: '1px solid #fff1',
        }}
      >
        <div className={classes.root} style={{ paddingTop: 10 }} />
        <Toolbar variant="dense" style={{ paddingRight: 0, paddingLeft: 0, justifyContent: 'space-between' }}>
          <Box sx={{ maxWidth: `${(width)}px`, width: '100%' }}>
            <StyledTabs
              value={value}
              allowScrollButtonsMobile
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              {
                tablist.map((d) => (
                  <StyledTab
                    to={d.to}
                    value={d.to}
                    component={Link}
                    key={d.name}
                    label={d.name}
                    className={classes.tab}
                  />
                ))
              }
            </StyledTabs>
          </Box>
        </Toolbar>
      </AppBar>
      <TabPanel value={value} index={value} style={{ minHeight: 300 }} className="arjitsongs">
        <Suspense fallback={<Loading />}>
          <Outlet context={{
            chapterno,
            setChapterNo,
            cverse,
            setCVerse,
            verseno,
            setVerseNo,
            autoplay,
            setAutoPlay,
          }}
          />
        </Suspense>
      </TabPanel>
    </div>
  );
}

export default withRouter(GitaLayout);
