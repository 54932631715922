/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function TabPanel(props) {
  const {
    children, value, index, sx, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <Box sx={{ p: 2, ...sx }}>
        <Typography component="div">{children}</Typography>
      </Box>
      )}
    </div>
  );
}
