/* eslint-disable indent */
import { combineReducers } from 'redux';
import weatherReducer from './weatherReducer';
import radioReducer from './radioReducer';
import arjitReducer from './arjitReducer';
import musicReducer from './musicReducer';
import gitaReducer from './gitaReducer';

export default combineReducers({
    weather: weatherReducer,
    radio: radioReducer,
    arjit: arjitReducer,
    music: musicReducer,
    gita: gitaReducer,
});
