/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import {
    AccountTree, Call, Home, Cloud, AccessTime, Numbers, Radio, MusicNote, TempleHindu,
} from '@mui/icons-material';

export const menuItems = {
    data: [
        {
            name: 'Home',
            path: '/',
            FaIcon: Home,
            category: 'resume',
        },
        {
            name: 'Portfolio',
            path: '/portfolio',
            FaIcon: AccountTree,
            category: 'resume',
        },
        {
            name: 'Contact',
            path: '/contact',
            FaIcon: Call,
            category: 'resume',
        },
        {
            name: 'Bagavadgita',
            path: '/gita/home',
            FaIcon: TempleHindu,
            category: 'apps',
        },
        // {
        //     name: 'Arjit World',
        //     path: '/secure/arjitworld',
        //     FaIcon: MusicNote,
        //     category: 'secure',
        //     secure: true,
        // },
        // {
        //     name: 'Global Radio',
        //     path: '/radio',
        //     FaIcon: Radio,
        //     category: 'apps',
        // },
        {
            name: 'Weather',
            path: '/weather',
            FaIcon: Cloud,
            category: 'apps',
        },
        {
            name: 'World Clock',
            path: '/worldclock',
            FaIcon: AccessTime,
            category: 'apps',
        },
        {
            name: 'Number System',
            path: '/numbersystem',
            FaIcon: Numbers,
            category: 'apps',
        },
    ],
};
